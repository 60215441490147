<template>
	<intersect @enter.once="onVisible">
		<div>
			<main-footer-eng v-if="$i18n.locale === 'en'" />
			<div
				v-else
				id="main_footer"
				class="panel footer theme-dark pt-0"
			>
				<div :class="fluid ? 'container-fluid' : 'container'">
					<div class="border-bottom pb-4 mb-4 pt-3">
						<div class="row align-items-center">
							<div class="col-9 col-sm-6 col-md-5 col-lg-2 mb-3 mb-sm-0">
								<a href="/" class="logo">
									<b-img-lazy src="/frontimg/logo-skolkovo-eq-wh.png" alt="logo" />
								</a>
							</div>
							<div
								:class="[
									'col-12',
									'col-sm-5',
									'col-md-5',
									'col-lg-7',
									'offset-sm-1',
									'offset-md-2',
									'offset-lg-1',
									'contact_block',
								]"
							>
								<div class="row align-items-center">

									<div v-if="isMenuVisible" class="col-12 col-lg-4 mt-2 contact_block mb-3 mb-lg-0">
										<div class="mb-0">
											Приемная комиссия
										</div>
										<a href="tel:+74952800680" class="contact icon-phone mb-0">+7 495 280 06 80</a>
										<a href="mailto:admissions@skolkovo.ru" class="contact icon-email mb-0">admissions@skolkovo.ru</a>
									</div>

									<div v-if="isMenuVisible" class="col-12 col-lg-4 mt-2 contact_block mb-3 mb-lg-0">
										<div class="mb-0">
											По общим вопросам
										</div>
										<a href="tel:+74952800681" class="contact icon-phone mb-0">+7 495 280 06 81</a>
										<a href="mailto:info@skolkovo.ru" class="contact icon-email mb-0">info@skolkovo.ru</a>
									</div>


									<div v-if="!isMenuVisible" class="col-12 col-lg-4 mt-2">
										<a href="tel:+74955393003" class="contact icon-phone">+7 495 539 30 03</a>
									</div>
									<div v-if="!isMenuVisible" class="col-12 col-lg-4 mt-2">
										<a
											href="mailto:info@skolkovo.ru"
											class="contact icon-email"
										>info@skolkovo.ru</a>
									</div>


									<div class="col-10 col-sm-12 col-md-8 col-lg-4 mt-2 pr-lg-0  mb-3 mb-lg-0">
										<div class="share">
											<div class="share_list">
												<a
													href="//t.me/skolkovo_channel"
													target="_blank"
													aria-label="telegram"
												><span class="icon-telegram" /></a>
												<a
													href="//www.youtube.com/user/skolkovoschool"
													target="_blank"
													class="yt-icon"
													aria-label="YouTube"
												>
													<span class="icon-yt" />
												</a>
												<a
													href="//vk.com/skolkovoschool"
													target="_blank"
													aria-label="vk"
												><span class="icon-vk" /></a>
												<a
													href="//zen.yandex.ru/id/5bfeb27acd7ace00a9d64b0d"
													target="_blank"
													class="zen-icon"
													aria-label="Yandex Zen"
												>
													<span class="icon-zen" />
												</a>
												<a
													href="//vc.ru/s/shkola-upravleniya-skolkovo-84321"
													target="_blank"
													aria-label="vc"
												><span class="icon-logo-vc-ru" /></a>
												<a
													href="https://tenchat.ru/Skolkovoschool"
													target="_blank"
													aria-label="TenChat"
												>
													<div class="tenchat d-flex">
														<TenChat />
													</div>
												</a>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="col-12 col-lg-2 text-lg-right">
								<a href="/donation/" class="btn btn-primary">{{ $t('supportSchool') }}</a>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-12 col-md-6 col-lg-3 mb-5">
							<div class="menu-box">
								<ul class="list-unstyled footer-main-menu title">
									<li>
										<a
											target="_blank"
											href="/navigator/programmes/?typologies=1"
										>
											{{ $t('education') }}
										</a>
									</li>
									<li>
										<a href="/navigator/events/">{{ $t('events') }}</a>
									</li>
									<li>
										<a target="_blank" href="/academic/">{{ $t('academic') }}</a>
									</li>
									<li>
										<a href="/intrend/">{{ $t('media') }}</a>
									</li>
									<li>
										<nuxt-link :to="{ name: 'campus' }">
											{{ $t('campus') }}
										</nuxt-link>
									</li>
									<li>
										<a target="_blank" href="/alumni/">{{ $t('alumni') }}</a>
									</li>
									<li>
										<a target="_blank" href="/loyalty-system/">{{ $t('loyaltyProgram') }}</a>
									</li>
								</ul>
							</div>
						</div>
						<div class="col-12 col-md-6 col-lg-3 mb-5">
							<div class="menu-box">
								<div>
									<div class="title">
										<a href="/about-school/">{{ $t('aboutSchool') }}</a>
									</div>
									<ul class="list-unstyled footer-sub-menu">
										<li>
											<a href="/about-school/management/">{{ $t('management') }}</a>
										</li>
										<li>
											<a href="/about-school/campus/">{{ $t('campus') }}</a>
										</li>
										<li>
											<a href="https://shop.skolkovo.ru/">{{ $t('skolkovoShop') }}</a>
										</li>
										<li>
											<a href="/about-school/sustainability/">{{ $t('sustainability') }}</a>
										</li>
										<li v-if="isMenuVisible">
											<a href="https://bbask.ru/sveden/">{{ $t('InformationAboutOrganization') }}</a>
										</li>
										<li v-else>
											<a href="https://www.skolkovo.ru/sveden/">{{ $t('InformationAboutOrganization') }}</a>
										</li>
										<li>
											<a href="/about-school/skolkovo-career/">
												{{ $t('career') }}
											</a>
										</li>
									</ul>

									<div class="title">
										<a href="/about-school/contacts/">{{ $t('contacts') }}</a>
									</div>
								</div>
							</div>
						</div>

						<!-- <div v-if="isMenuVisible" class="col-12 col-md-6 col-lg-6 mb-5 contact_block">
							<div class="title">
								Контакты бакалавриата
							</div>

							<div class="d-flex flex-column">
								<div class="mb-0">
									Телефон приемной комиссии
								</div>
								<a href="tel:+74952800680" class="contact icon-phone mb-3">+7 495 280 06 80</a>
								<div class="mb-0">
									Общий телефон
								</div>
								<a href="tel:+74952800681" class="contact icon-phone mb-3">+7 495 280 06 81</a>
							</div>

							<div class="d-flex flex-column">
								<div class="mb-0">
									По общим вопросам
								</div>
								<a
									href="mailto:bba@skolkovo.ru"
									class="contact icon-email mb-3"
								>bba@skolkovo.ru</a>
								<div class="mb-0">
									По вопросам поступления
								</div>
								<a
									href="mailto:admissions@skolkovo.ru"
									class="contact icon-email mb-3"
								>admissions@skolkovo.ru</a>
							</div>
						</div> -->



						<div class="col-12 col-md-6 col-lg-3 mb-5">
							<div class="menu-box events">
								<div>
									<div class="subtitle text-muted">
										{{ $t('educationalPrograms') }}
									</div>
									<b-skeleton-wrapper class="footer-event-menu" :loading="loading">
										<template #loading>
											<b-skeleton />
											<b-skeleton />
											<b-skeleton />
										</template>
										<b-row v-if="error">
											{{ error }}
										</b-row>
										<ul v-else class="list-unstyled footer-event-menu">
											<li v-for="program in programs" :key="program.id">
												<div class="date">
													<div class="date-day">
														{{ program.event_date | moment('DD.MM') }}
													</div>
													<div class="date-day-of-week">
														{{ program.event_date | moment('dd.') }}
													</div>
												</div>
												<div class="subtitle">
													<nuxt-link :to="$url.event(program.slug, program.typology_slug)">
														{{ program.title }}
													</nuxt-link>
												</div>
											</li>
										</ul>
									</b-skeleton-wrapper>
								</div>
								<a
									href="/navigator/programmes/?typologies=1"
									class="btn btn-secondary"
								>{{ $t('allPrograms') }}</a>
							</div>
						</div>

						<div class="col-12 col-md-6 col-lg-3 mb-5">
							<div class="menu-box events">
								<div>
									<div class="subtitle text-muted">
										{{ $t('eventAnnounces') }}
									</div>
									<b-skeleton-wrapper class="footer-event-menu" :loading="loading">
										<template #loading>
											<b-skeleton />
											<b-skeleton />
											<b-skeleton />
										</template>
										<ul class="list-unstyled footer-event-menu">
											<li v-for="event in events" :key="event.id">
												<div class="date">
													<div class="date-day">
														{{ event.event_date | moment('DD.MM') }}
													</div>
													<div class="date-day-of-week">
														{{ event.event_date | moment('dd.') }}
													</div>
												</div>
												<div class="subtitle">
													<nuxt-link :to="$url.event(event.slug, event.typology_slug)">
														{{ event.title }}
													</nuxt-link>
												</div>
											</li>
										</ul>
									</b-skeleton-wrapper>
								</div>

								<nuxt-link class="btn btn-secondary" :to="$url.events()">
									{{ $t('allEvents') }}
								</nuxt-link>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-12">
							<p class="small text-muted">
								&copy;2024 {{ $t('copyright') }}<br />
								<a
									role="button"
									class="int-prop mr-2"
									@click="showModal()"
								>{{ $t('intellectualProperty') }}.</a>
								<a class="int-prop mr-2" href="https://www.skolkovo.ru/privacy/">Политика конфиденциальности.</a>
								<a class="int-prop mr-2" href="https://www.skolkovo.ru/static/cookie_rus.html">Политика Школы в отношении файлов cookie.</a>
							</p>
							<p class="small text-muted">
								Вся представленная на сайте информация носит исключительно информационно-справочный характер и ни при каких условиях не является публичной офертой, определяемой положениями статьи 437 Гражданского кодекса Российской Федерации (за исключением случаев, прямо указанных на сайте). За получением подробной информации об условиях обучения и оказания иных услуг Вы можете обратиться к консультантам Школы управления СКОЛКОВО.
							</p>
						</div>
					</div>
				</div>

				<ScrollTopComponent />

				<b-modal id="intellect-property">
					<template #modal-title>
						{{ $t('intellectualProperty') }}
					</template>
					<div class="d-block text-center">
						<p>
							Бизнес-школа СКОЛКОВО уважает чужие права на объекты интеллектуальной собственности и
							принимает все необходимые меры, чтобы их не нарушать.
						</p>
						<p>
							В случае выявления случая нарушения Ваших интеллектуальных прав
							и иных противоправных действий
							других пользователей Веб-сайта, просим сообщить нам об этом на адрес
							<a
								href="mailto:abuse@skolkovo.ru"
							>abuse@skolkovo.ru</a>.
						</p>
					</div>
					<template #modal-footer="{ ok }">
						<b-button size="sm" variant="success" @click="ok()">
							OK
						</b-button>
					</template>
				</b-modal>
			</div>
		</div>
	</intersect>
</template>

<script>
import '@/utils/moment';
import Intersect from 'vue-intersect';
import { mapActions } from 'vuex';
import ScrollTopComponent from '~/components/ScrollTopComponent.vue';
import MainFooterEng from '~/components/layouts/footers/MainFooterEng.vue';
import TenChat from '~/static/svg/tenchat.vue';

const firstIdArr = [18435];

export default {
	name: 'MainFooter',
	components: {
		ScrollTopComponent,
		MainFooterEng,
		Intersect,
		TenChat,
	},
	props: {
		fetchedData: { type: Object, default: () => ({}) },
		fluid: { type: Boolean, default: false },
	},
	data() {
		return {
			programs: [],
			events: [],
			isTopButtonActive: false,
			modalIntellectPropertySrc: false,
			error: null,
			loading: true,
			isMenuVisible: false,
		};
	},
	mounted() {
		this.isMenuVisible = firstIdArr.includes(this.fetchedData.id);
	},
	methods: {
		...mapActions({
			showLoginPopup: 'authModal/showLogin',
			showRegistrationPopup: 'authModal/showRegistration',
		}),
		showModal(src) {
			this.$bvModal.show('intellect-property');
			this.modalIntellectPropertySrc = src;
		},
		onVisible() {
			this.load();
		},
		async load() {
			try {
				const [events, programs] = await Promise.all([
					this.$api.events({ typology: 0, per_page: 4, index_list: true }),
					this.$api.events({ typology: 1, per_page: 4, index_list: true }),
				]);
				this.events = events.data;
				this.programs = programs.data;
			} catch (error) {
				this.$logger.log(error);
				this.error = 'Что-то пошло не так, но мы уже знаем об этом';
			} finally {
				this.loading = false;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
	@import "~/assets/styles/components/layouts/main-footer-panel";

	.tenchat {
		height: 20px;
		width: 20px;
	}
</style>

export function state() {
	return {
		unwatchedSpecialPromotionsCount: 0,
	};
}

export const actions = {
};

export const getters = {
	unwatchedSpecialPromotionsCount: (state) => state.unwatchedSpecialPromotionsCount,
};

export const mutations = {
	setUnwatchedSpecialPromotions(state, count) {
		state.unwatchedSpecialPromotionsCount = count;
	},
};

export const TYPES_OF_ORDERS = {
	REGISTRATION: 1,
	PAYMENT: 2,
	DONATION: 3,
};

export const BOOTSTRAP_BREAKPOINTS = {
	xs: 0,
	sm: 576,
	md: 768,
	lg: 1260,
	xl: 2000,
};

export const HEADER_IMAGE_POSITIONS = {
	1: 'center center',
	2: 'left center',
	3: 'right center',
	4: 'right bottom',
	5: 'left bottom',
	6: 'right top',
	7: 'left top',
};

export const TYPOLOGY = {
	EVENT: 0,
	PROGRAM: 1,
};

export const SCENARIOS = {
	BUY_PROGRAM: 0,
	REGISTRATION: 1,
	DONATION: 2,
	LEADSEND: 3,
};

export const EVENTTYPE = {
	DIALOG: 11,
};

export const CONTENT_TYPE = {
	TEXT: 0,
	VIDEO: 1,
	PODCAST: 2,
};

export const ROLES = {
	NO: 0,
	TEACHER: 1,
	STUDENT: 2,
	SPEAKER: 3,
};

export const CONTENT_TAXONOMY_TYPES = {
	NO: 0,
	RESEARCH: 1,
	EXPERT_OPINION: 2,
	INTERVIEW: 3,
	NEWS: 4,
	NOTE: 5,
	CASE: 6,
	REVIEW: 7,
	QA: 8,
};

export const EVENT_FORM_SCENARIOS = {
	REQUEST_PRESENTATION: 'REQUEST_PRESENTATION',
	REQUEST_CALL_BACK: 'REQUEST_CALL_BACK',
	REQUEST_INVOICE: 'REQUEST_INVOICE',
	REQUEST_CONSULTATION: 'REQUEST_CONSULTATION',
	REQUEST_SUBSCRIPTION: 'REQUEST_SUBSCRIPTION',
	REQUEST_STREAM_ACCESS: 'REQUEST_STREAM_ACCESS',
	REQUEST_CUSTOM: 'REQUEST_CUSTOM',
	REGISTRATION: 'REGISTRATION',
};

export const STATUSES = {
	CONTINUE: 100,
	SWITCHING_PROTOCOLS: 101,
	PROCESSING: 102,
	OK: 200,
	CREATED: 201,
	ACCEPTED: 202,
	NON_AUTHORITATIVE_INFORMATION: 203,
	NO_CONTENT: 204,
	RESET_CONTENT: 205,
	PARTIAL_CONTENT: 206,
	MULTI_STATUS: 207,
	MULTIPLE_CHOICES: 300,
	MOVED_PERMANENTLY: 301,
	MOVED_TEMPORARILY: 302,
	SEE_OTHER: 303,
	NOT_MODIFIED: 304,
	USE_PROXY: 305,
	TEMPORARY_REDIRECT: 307,
	BAD_REQUEST: 400,
	UNAUTHORIZED: 401,
	PAYMENT_REQUIRED: 402,
	FORBIDDEN: 403,
	NOT_FOUND: 404,
	METHOD_NOT_ALLOWED: 405,
	NOT_ACCEPTABLE: 406,
	PROXY_AUTHENTICATION_REQUIRED: 407,
	REQUEST_TIME_OUT: 408,
	CONFLICT: 409,
	GONE: 410,
	LENGTH_REQUIRED: 411,
	PRECONDITION_FAILED: 412,
	REQUEST_ENTITY_TOO_LARGE: 413,
	REQUEST_URI_TOO_LARGE: 414,
	UNSUPPORTED_MEDIA_TYPE: 415,
	REQUESTED_RANGE_NOT_SATISFIABLE: 416,
	EXPECTATION_FAILED: 417,
	I_M_A_TEAPOT: 418,
	UNPROCESSABLE_ENTITY: 422,
	LOCKED: 423,
	FAILED_DEPENDENCY: 424,
	UNORDERED_COLLECTION: 425,
	UPGRADE_REQUIRED: 426,
	PRECONDITION_REQUIRED: 428,
	TOO_MANY_REQUESTS: 429,
	REQUEST_HEADER_FIELDS_TOO_LARGE: 431,
	INTERNAL_SERVER_ERROR: 500,
	NOT_IMPLEMENTED: 501,
	BAD_GATEWAY: 502,
	SERVICE_UNAVAILABLE: 503,
	GATEWAY_TIME_OUT: 504,
	HTTP_VERSION_NOT_SUPPORTED: 505,
	VARIANT_ALSO_NEGOTIATES: 506,
	INSUFFICIENT_STORAGE: 507,
	BANDWIDTH_LIMIT_EXCEEDED: 509,
	NOT_EXTENDED: 510,
	NETWORK_AUTHENTICATION_REQUIRED: 511,
};

export const FORMULATE_ORDER_STATUSES = {
	NOT_PAID: 1,
	PAID: 2,
	CHECK: 3,
	ACCEPTED: 4,
	CANCEL: 5,
	FAIL: 6,
	ADDITIONAL_PAYMENT: 7,
	PAID_AT_THE_CHECKOUT: 8,
};

export const FORMULATE_INVOICES_STATUSES = {
	NOT_PAID: 0,
	PAID: 1,
	FAIL: 2,
};

export const FORMULATE_PRODUCT_TYPE = {
	PRODUCT_DONT_SHOW: 0,
	PRODUCT_CHECKBOX: 1,
	PRODUCT_RADIO: 2,
	PRODUCT_SELECT: 3,
	PRODUCT_QUANTITY: 4,
};

export const FORMULATE_DOCUMENTS_STATUSES = {
	NOT_SENT: 1,
	CHECK: 2,
	ACCEPTED: 3,
};

export const USER_PRODUCT_STATUSES = {
	STATUS_ACCEPTED: 1,
	STATUS_NOT_PAID: 2,
	STATUS_PAID: 3,
	STATUS_CANCEL: 4,
	STATUS_FAIL: 5,
	STATUS_GO_TO_STUDY: 6,
	STATUS_REJECTION: 7,
	STATUS_ADDITIONAL_PAYMENT: 8,
};

export const ORDER_TYPES = {
	FREE: 1,
	PAID: 2,
	DONATION: 3,
};

export const PROGRAM_STEPS = {
	PAYMENT: 1,
	DOCUMENTS: 2,
	ACCESS: 3,
};

export const DOCUMENTS_VERIFICATION_TYPES = {
	SMALL: 1,
	ADMISSION: 2,
};

export const ADMISSION_STATUSES = {
	NOT_STARTED: 0,
	ACTIVE: 1,
	COMPLETED: 2,
	REJECTED: 3,
	UNDER_CONSIDERATION: 4,
	REFUSED: 5,
};

export const LOYALTY_LEVELS = [
	{
		title: 'Уровень 1',
		name: 'Bronze',
		id: 1,
		image: 'https://sk.skolkovo.ru/img/3e8a8fe9-49c5-41c9-8c87-00de2c98c206/bronze.png',
		need_to_pay_programs: '5-99,9 тыс руб',
		need_to_donate: '5-99,9 тыс руб',
		color: '#E7E0D0',
	},
	{
		title: 'Уровень 2',
		name: 'Silver',
		id: 2,
		image: 'https://sk.skolkovo.ru/img/b8c48963-ff2f-4cc1-804b-932cd7b490c8/silver.png',
		need_to_pay_programs: '300-999 тыс руб',
		need_to_donate: '100-499 тыс руб',
		color: '#D8D8D9',
	},
	{
		title: 'Уровень 3',
		name: 'Gold',
		id: 3,
		image: 'https://sk.skolkovo.ru/img/91b349c3-458d-4344-8c19-ad4bd097acfe/gold.png',
		need_to_pay_programs: '1-3,99 млн руб',
		need_to_donate: '500-999 тыс руб',
		color: '#E9E2C4',
	},
	{
		title: 'Уровень 4',
		name: 'Platinum',
		id: 4,
		image: 'https://sk.skolkovo.ru/img/49160b8f-acf1-4852-a31e-c6964b1ec142/platinum.png',
		need_to_pay_programs: '4-8,99 млн руб',
		need_to_donate: '1-4,99 млн руб',
		color: '#E2E7F3',
	},
	{
		title: 'Уровень 5',
		name: 'Diamond',
		id: 5,
		image: 'https://sk.skolkovo.ru/img/3c8ad7ff-2ccd-4e27-8088-c9438bf9057e/diamond.png',
		need_to_pay_programs: '9+ млн руб',
		need_to_donate: '5+ млн руб',
		color: '#E2E7F3',
	},
];

export const QR_STATUSES = {
	STATUS_NOT_ACTIVATED: 1,
	STATUS_DECLINED: 2,
	STATUS_APPROVED: 3,
};

export const ORDER_TYPE = {
	PROGRAM: 1,
	EVENT: 2,
	MEDIA: 3,
	PAGE: 4,
};

export const FORMULATE_FIELDS_TYPES = {
	TYPE_TEXT: 1,
	TYPE_FILE: 2,
	TYPE_JSON: 3,
	TYPE_ARRAY: 4,
};

export const STUDY_STATUSES = {
	STUDY_STATUS_REGISTERED: 1,
	STUDY_STATUS: 2,
	STUDY_STATUS_EXPELLED: 3,
	STUDY_STATUS_ALUMNI: 4,
	STUDY_STATUS_ALUMNI_THIS_YEAR: 5,
	STUDY_STATUS_ADMISSION: 6,
};

export const ELECTIVE_STATUS = {
	STATUS_DRAFT: 1,
	STATUS_PUBLIC: 2,
	STATUS_COMPLETED: 3,
	STATUS_CANCELED: 4,
};

export const ELECTIVE_ORDER_STATUS = {
	STATUS_MODERATION: 1,
	STATUS_ADMISSION: 2,
	STATUS_WAIT_LIST: 3,
	STATUS_APPROVED: 4,
	STATUS_CANCELED: 5,
};
